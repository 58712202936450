import { Environment } from './environment.model';

export const environment: Environment = {
  productionMode: true,
  isLocal: false,
  googleTagManagerId: 'GTM-MW8KS58',
  prismicEndpoint: 'https://lebara-support.cdn.prismic.io/api/v2',
  brand: 'lebara',
  fallbackLang: 'de',
  newMicroServiceEndpoint: 'https://ms.lebara.ch',
  checkoutUrl: 'https://checkout.lebara.ch',
  allowedMsEnvs: [],
  defaultUrlsEnvironment: '',
  yalloTvUrl: 'na',
  distributionIds: [],
  featureFlagClientKey: 'sdk-1AyWzT53SObto3SV',
  selfcareUrl: 'https://my.lebara.ch',
  sprinklerAppId: '66c60bb29bfd097343b32b8c_app_444',
};
